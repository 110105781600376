import React from "react";

const Feedback = () => {
  return (
    <p>
      <strong> Email: </strong>
      <a href="mailto:contact.salimdridigmail.com">
        contact.salimdridi@gmail.com
      </a>
    </p>
  );
};

export default Feedback;
